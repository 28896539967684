import React from 'react';

function Frontend() {
  const FrontendData = [
    {
      name: 'React Js',
      level: 'Advanced',
    },
    {
      name: 'React Native',
      level: 'Advanced',
    },
    {
      name: 'Flutter',
      level: 'Intermediate',
    },
    {
      name: 'Node js',
      level: 'Intermediate',
    },
    {
      name: 'Angular',
      level: 'Intermediate',
    },
    {
      name: 'Ember',
      level: 'Intermediate',
    },
    // {
    //   name: 'Git',
    //   level: 'Intermediate',
    // },
    {
      name: 'Express',
      level: 'Intermediate',
    },
    {
      name: 'Ethers.js',
      level: 'Intermediate',
    },
  ];
  const middleIndex = Math.ceil(FrontendData.length / 2);

  const firstHalf = FrontendData.splice(0, middleIndex);
  const secondHalf = FrontendData.splice(-middleIndex);

  return (
    <div className="skills__content">
      <h3 className="skills__title">WEB TOOL & FRAMEWORKS</h3>
      <div className="skills__box">
        <div className="skills__group">
          {firstHalf.map((item) => (
            <div className="skills__data" key={item.name}>
              <i className="bx bx-badge-check"></i>
              <div>
                <h3 className="skills__name">{item.name}</h3>
                <span className="skills__level">{item.level}</span>
              </div>
            </div>
          ))}
        </div>
        <div className="skills__group">
          {secondHalf.map((item) => (
            <div className="skills__data" key={item.name}>
              <i className="bx bx-badge-check"></i>
              <div>
                <h3 className="skills__name">{item.name}</h3>
                <span className="skills__level">{item.level}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Frontend;
