import React, { useState } from 'react';
import './qualification.css';

function Qualification() {
  const [toogleState, setToogleState] = useState(0);

  return (
    <section className="qualification section">
      <h2 className="section__title">Qualification</h2>
      <span className="section__subtitle">My personel journey</span>
      <div className="qualification__container container">
        <div className="qualification__tabs">
          <div
            className={`qualification__button button--flex ${
              toogleState == 0 ? 'qualification__active' : ''
            }`}
            onClick={() => setToogleState(0)}
          >
            <i className="uil uil-briefcase-alt qualification__icon"></i>
            Experience
          </div>
          <div
            className={`qualification__button button--flex ${
              toogleState == 1 ? 'qualification__active' : ''
            }`}
            onClick={() => setToogleState(1)}
          >
            <i className="uil uil-graduation-cap qualification__icon"></i>
            Education
          </div>
        </div>

        <div className="qualification__sections">
          <div
            className={`qualification__content ${
              toogleState == 0 ? 'qualification__content-active' : ''
            } `}
          >
            <div className="qualification__data">
              <div>
                <h3 className="qualification__title">Product Engineer</h3>
                <span className="qualification__subtitle">
                  tanX.fi · Full-time
                </span>
                <div className="qualification__calender">
                  <i className="uil uil-calender-alt"></i>Aug 2022 - Present
                </div>
              </div>
              <div>
                <span className="qualification__rounder"></span>
                <div className="qualification__line"></div>
              </div>
            </div>

            <div className="qualification__data">
              <div></div>
              <div>
                <span className="qualification__rounder"></span>
                <span className="qualification__line"></span>
              </div>
              <div>
                <h3 className="qualification__title">Product Engineer</h3>
                <span className="qualification__subtitle">
                  Krypto · Full-time
                </span>
                <div className="qualification__calender">
                  <i className="uil uil-calender-alt"></i>Aug 2021 - Jul 2022 ·
                  1 yr
                </div>
              </div>
              {/* <div>
                <span className='qualification__rounder'></span>
                <span className='qualification__line'></span>
              </div> */}
            </div>

            <div className="qualification__data">
              <div>
                <h3 className="qualification__title">Frontend Developer</h3>
                <span className="qualification__subtitle">
                  Tenantcube · Internship
                </span>
                <div className="qualification__calender">
                  <i className="uil uil-calender-alt"></i>Jul 2021 - Aug 2021 ·
                  2 mos
                </div>
              </div>
              <div>
                <span className="qualification__rounder"></span>
                <div className="qualification__line"></div>
              </div>
            </div>

            <div className="qualification__data">
              <div></div>
              <div>
                <span className="qualification__rounder"></span>
                <span className="qualification__line"></span>
              </div>
              <div>
                <h3 className="qualification__title">Software Developer</h3>
                <span className="qualification__subtitle">
                  Wylo · Internship
                </span>
                <div className="qualification__calender">
                  <i className="uil uil-calender-alt"></i>May 2021 - Jun 2021 ·
                  2 mos
                </div>
              </div>
              {/* <div>
                <span className='qualification__rounder'></span>
                <span className='qualification__line'></span>
              </div> */}
            </div>
          </div>

          <div
            className={`qualification__content ${
              toogleState == 1 ? 'qualification__content-active' : ''
            } `}
          >
            <div className="qualification__data">
              <div>
                <h3 className="qualification__title">
                  Bachelor of Engineering (Computer Science and Engineering)
                </h3>
                <span className="qualification__subtitle">
                  Sri Ramakrishna Institute of Technology - 8/10 - CGPA
                </span>
                <div className="qualification__calender">
                  <i className="uil uil-calender-alt"></i>2018 - 2022
                </div>
              </div>
              <div>
                <span className="qualification__rounder"></span>
                <span className="qualification__line"></span>
              </div>
            </div>

            <div className="qualification__data">
              <div></div>
              <div>
                <span className="qualification__rounder"></span>
                <span className="qualification__line"></span>
              </div>
              <div>
                <h3 className="qualification__title">
                  NAGAMANI AMMAL MEMORIAL MATRICULATION HIGHER SECONDARY SCHOOL
                </h3>
                <span className="qualification__subtitle">
                  Computer science group (HSLC) - 81.50%
                </span>
                <div className="qualification__calender">
                  <i className="uil uil-calender-alt"></i>2017 - 2018
                </div>
              </div>
              {/* <div>
                <span className='qualification__rounder'></span>
                <span className='qualification__line'></span>
              </div> */}
            </div>

            <div className="qualification__data">
              <div>
                <h3 className="qualification__title">
                  NAGAMANI AMMAL MEMORIAL MATRICULATION HIGHER SECONDARY SCHOOL
                </h3>
                <span className="qualification__subtitle">
                  State Board (SSLC) - 88.60 %
                </span>
                <div className="qualification__calender">
                  <i className="uil uil-calender-alt"></i>2015 - 2016
                </div>
              </div>
              <div>
                <span className="qualification__rounder"></span>
                <div className="qualification__line"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Qualification;
