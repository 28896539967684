import React from 'react';

function Backend() {
  let BackendData = [
    {
      name: 'HTML',
      level: 'Advanced',
    },
    {
      name: 'CSS',
      level: 'Advanced',
    },
    {
      name: 'Javascript',
      level: 'Advanced',
    },
    {
      name: 'Solidity',
      level: 'Intermediate',
    },
    {
      name: 'Python',
      level: 'Intermediate',
    },
    {
      name: 'Java',
      level: 'Intermediate',
    },
    {
      name: 'Dart',
      level: 'Intermediate',
    },
    {
      name: 'GoLang',
      level: 'Beginner',
    },
  ];

  const middleIndex = Math.ceil(BackendData.length / 2);

  const firstHalf = BackendData.splice(0, middleIndex);
  const secondHalf = BackendData.splice(-middleIndex);

  return (
    <div className="skills__content">
      <h3 className="skills__title">TECHNICAL & PROGRAMMING SKILLS</h3>
      <div className="skills__box">
        <div className="skills__group">
          {firstHalf.map((item) => (
            <div className="skills__data" key={item.name}>
              <i className="bx bx-badge-check"></i>
              <div>
                <h3 className="skills__name">{item.name}</h3>
                <span className="skills__level">{item.level}</span>
              </div>
            </div>
          ))}
        </div>
        <div className="skills__group">
          {secondHalf.map((item) => (
            <div className="skills__data" key={item.name}>
              <i className="bx bx-badge-check"></i>
              <div>
                <h3 className="skills__name">{item.name}</h3>
                <span className="skills__level">{item.level}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Backend;
