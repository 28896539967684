import React, { useRef } from 'react';
import { SendSvg } from '../Home/Info/Info';
import './contact.css';
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Contact() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        'service_fr0pq24',
        'template_7p468br',
        form.current,
        'o9eEmhq8Y6JXiwqUu'
      )
      .then(
        (result) => {
          console.log(result.text);
          toast('Successfully sent!', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
            toastId: 'success',
          });
        },
        (error) => {
          //   console.log();
          toast.error(error.text, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
            toastId: 'error',
          });
        }
      );
  };

  return (
    <div className='contact section' id='contact'>
      <ToastContainer className='toast_style' />

      <h2 className='section__title'>Get in touch</h2>
      <span className='section__subtitle'>Contact Me</span>

      <div className='contact__container container contaner grid'>
        <div className='contact__content'>
          <h3 className='contact__title'>Talk to me</h3>
          <div className='contact__info'>
            <div className='contact__card'>
              <i className='bx bx-mail-send contact__card-icon'></i>
              <h3 className='contact__card-title'>Email</h3>
              <span className='contact__card-data'>
                gurukishore222@gmail.com
              </span>

              <a
                href='mailto:gurukishore222@gmail.com'
                className='contact__button'
              >
                Write me{' '}
                <i className='bx bx-right-arrow-alt contact__button-icon'></i>
              </a>
            </div>

            <div className='contact__card'>
              <i className='uil uil-whatsapp contact__card-icon'></i>
              <h3 className='contact__card-title'>Whatsapp</h3>
              <span className='contact__card-data'>6379397477</span>

              <a
                href='https://api.whatsapp.com/send?phone=6379397477&text=Hello, from portfolio'
                className='contact__button'
              >
                Write me{' '}
                <i className='bx bx-right-arrow-alt contact__button-icon'></i>
              </a>
            </div>
          </div>
        </div>

        <div className='contact__content'>
          <h3 className='contact__title'>Write me about your project</h3>

          <form className='contact__form' ref={form} onSubmit={sendEmail}>
            <div className='contact__form-div'>
              <label className='contact__form-tag'>Email</label>
              <input
                type='email'
                className='contact__form-input'
                placeholder='Type your email'
                name='email'
              />
            </div>
            <div className='contact__form-div'>
              <label className='contact__form-tag'>Name</label>
              <input
                type='text'
                className='contact__form-input'
                placeholder='Type your name'
                name='name'
              />
            </div>

            <div className='contact__form-div contact__form-area'>
              <label className='contact__form-tag'>Message</label>
              <textarea
                name='message'
                type='text'
                className='contact__form-input '
                placeholder='Message'
                cols='30'
                rows='10'
              ></textarea>
            </div>

            <button className='button button--flex' onClick={sendEmail}>
              Send Message
              <SendSvg />
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Contact;
