import React from 'react';
import { useState, useEffect } from 'react';
import './header.css';

const Header = () => {
  const [toogleNav, setToogleNav] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      const header = document.querySelector('.header');
      if (window.scrollY >= 80) {
        header.classList.add('scroll-header');
      } else {
        header.classList.remove('scroll-header');
      }
    });
  }, []);

  return (
    <header className='header'>
      <nav className='nav container'>
        <a href='index.html' className='nav__logo' rel='noreferrer'>
          Guru Kishore
        </a>
        <div className={`nav__menu ${toogleNav ? 'show-menu' : ''}`}>
          <ul className='nav__list grid'>
            <li className='nav__item active-link'>
              <a href='#home' className='nav__link' rel='noreferrer'>
                <i className='uil uil-estate nav__icon'></i> Home
              </a>
            </li>

            <li className='nav__item'>
              <a href='#about' className='nav__link' rel='noreferrer'>
                <i className='uil uil-user nav__icon'></i>
                About
              </a>
            </li>

            <li className='nav__item'>
              <a href='#skills' className='nav__link' rel='noreferrer'>
                <i className='uil uil-file-alt nav__icon'></i>
                Skill
              </a>
            </li>

            <li className='nav__item'>
              <a href='#achievements' className='nav__link' rel='noreferrer'>
                <i className='uil uil-briefcase-alt nav__icon'></i>
                Achievements
              </a>
            </li>

            <li className='nav__item'>
              <a href='#portfolio' className='nav__link' rel='noreferrer'>
                <i className='uil uil-scenery nav__icon'></i>
                Projects
              </a>
            </li>

            <li className='nav__item'>
              <a href='#contact' className='nav__link' rel='noreferrer'>
                <i className='uil uil-message nav__icon'></i>
                Contact
              </a>
            </li>
          </ul>
          <i
            className='uil uil-times nav__close'
            onClick={() => setToogleNav((prev) => !prev)}
          ></i>
        </div>
        <div
          className='nav__toggle'
          onClick={() => setToogleNav((prev) => !prev)}
        >
          <i className='uil uil-apps'></i>
          {/* <i className='uil uil-align-right'></i> */}
        </div>
      </nav>
    </header>
  );
};

export default Header;
