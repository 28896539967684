import React from 'react';
import Programming from './Programming/Programming';
import Frontend from './Frontend/Frontend';
import './skills.css';

function Skills() {
  return (
    <section className='skills section' id='skills'>
      <h2 className='section__title'>Skills</h2>
      <span
        className='section__subtitle'
        // style={{ visibility: 'hidden' }}
      >
        Proficiency
      </span>
      <div className='skills__container container grid'>
        <Frontend />
        <Programming />
        {/* <Web3 /> */}
      </div>
    </section>
  );
}

export default Skills;
