import React, { useEffect } from 'react';
import './scrollUp.css';

function ScrollUp() {
  useEffect(() => {
    window.addEventListener('scroll', () => {
      const scrollUp = document.querySelector('.scrollup');
      if (window.scrollY >= 560) {
        scrollUp.classList.add('show-scroll');
      } else {
        scrollUp.classList.remove('show-scroll');
      }
    });
  }, []);
  return (
    <a href='#home' className='scrollup' rel='noreferrer'>
      <i className='uil uil-arrow-up scrollup__icon'></i>
    </a>
  );
}

export default ScrollUp;
