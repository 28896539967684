import React from 'react';
import './about.css';
import AboutImg from '../../assets/img/profile.jpeg';
import Info from '../Info/Info';

function FileIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      className="button__icon"
      viewBox="0 0 24 24"
    >
      <path
        d="M15.25 22.75h-6c-5.43 0-7.75-2.32-7.75-7.75V9c0-5.43 2.32-7.75 7.75-7.75h5c.41 0 .75.34.75.75s-.34.75-.75.75h-5C4.64 2.75 3 4.39 3 9v6c0 4.61 1.64 6.25 6.25 6.25h6c4.61 0 6.25-1.64 6.25-6.25v-5c0-.41.34-.75.75-.75s.75.34.75.75v5c0 5.43-2.32 7.75-7.75 7.75z"
        fill="var(--container-color)"
      ></path>
      <path
        d="M22.25 10.75h-4c-3.42 0-4.75-1.33-4.75-4.75V2c0-.3.18-.58.46-.69.28-.12.6-.05.82.16l8 8a.751.751 0 01-.53 1.28zM15 3.81V6c0 2.58.67 3.25 3.25 3.25h2.19L15 3.81zM13.25 13.75h-6c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h6c.41 0 .75.34.75.75s-.34.75-.75.75zM11.25 17.75h-4c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h4c.41 0 .75.34.75.75s-.34.75-.75.75z"
        fill="var(--container-color)"
      ></path>
    </svg>
  );
}

function About() {
  return (
    <section className="about section" id="about">
      <h2 className="section__title">About Me</h2>
      <span className="section__subtitle">My Introduction</span>

      <div className="about__container container grid">
        <img src={AboutImg} alt="profile_pic" className="about__img" />
        <div className="about__data">
          <Info />

          <p className="about__description">
            Crazy full-stack developer exploring every teach stack to Create
            highly interactive front ends and user interfaces for your web and
            mobile applications and integration of third-party services such as
            Firebase/AWS/Digital Ocean.
          </p>

          <a
            href={
              'https://drive.google.com/file/d/1q97K4rzwEa0fIjRJmdw5K5-3BtiouUX2/view?usp=sharing'
            }
            className="button button--flex"
            target="_blank"
          >
            Download CV <FileIcon />
          </a>
        </div>
      </div>
    </section>
  );
}

export default About;
