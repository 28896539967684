import React from 'react';
import './home.css';
import Info from './Info/Info';
import ScrollDown from './ScrollDown/ScrollDown';
import Social from './Social/Social';
import animationData from '../../assets/json/Panda_Computer.json';
import Lottie from 'react-lottie';

const Home = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <div className="home section" id="home">
      <div className="home__container container grid">
        <div className="home__content grid">
          <Social />
          <div className="home__img">
            <Lottie options={defaultOptions} />
          </div>
          <Info />
        </div>
        <ScrollDown />
      </div>
    </div>
  );
};

export default Home;
