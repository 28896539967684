import React from 'react';

function Social() {
  return (
    <div className='home__social'>
      <a
        href='https://github.com/gurukishore111'
        className='home__social-icon'
        target='_blank'
      >
        <i className='uil uil-github-alt'></i>
      </a>
      <a
        href='https://www.linkedin.com/in/j-guru-kishore-/'
        className='home__social-icon'
        target='_blank'
      >
        <i className='uil uil-linkedin-alt'></i>
      </a>

      <a
        href='https://www.instagram.com/gk_photography_1?r=nametag'
        className='home__social-icon'
        target='_blank'
      >
        <i className='uil uil-instagram'></i>
      </a>
    </div>
  );
}

export default Social;
