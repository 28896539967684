import React from 'react';
import './footer.css';

function Footer() {
  return (
    <footer className='footer'>
      <div className='footer__container container'>
        <h1 className='footer__title'>Guru Kishore</h1>
        <ul className='footer__list'>
          <li>
            <a href='#about' className='footer__link' rel='noreferrer'>
              About
            </a>
          </li>
          <li>
            <a href='#portfolio' className='footer__link' rel='noreferrer'>
              Projects
            </a>
          </li>
          <li>
            <a href='#skills' className='footer__link' rel='noreferrer'>
              Skills
            </a>
          </li>
        </ul>

        <div className='footer__social'>
          <a
            href='https://github.com/gurukishore111'
            className='footer__social-link'
            target='_blank'
            rel='noreferrer'
          >
            <i className='uil uil-github-alt'></i>
          </a>
          <a
            href='https://www.linkedin.com/in/j-guru-kishore-/'
            className='footer__social-link'
            target='_blank'
            rel='noreferrer'
          >
            <i className='uil uil-linkedin-alt'></i>
          </a>

          <a
            href='https://www.instagram.com/gk_photography_1?r=nametag'
            className='footer__social-link'
            target='_blank'
            rel='noreferrer'
          >
            <i className='uil uil-instagram'></i>
          </a>
        </div>
        <span className='footer__copy'>
          &#169; Guru Kishore. All rights reserved
        </span>
      </div>
    </footer>
  );
}

export default Footer;
