import React from 'react';

function Info() {
  return (
    <div className='about__info grid'>
      <div className='about__box'>
        <div className='bx bx-award about__icon'></div>
        <h3 className='about__title'>Experience</h3>
        <span className='about__subtitle'>2 Years</span>
      </div>

      <div className='about__box'>
        <div className='bx bx-briefcase-alt about__icon'></div>
        <h3 className='about__title'>Completed</h3>
        <span className='about__subtitle'>30+ Projects</span>
      </div>
    </div>
  );
}

export default Info;
