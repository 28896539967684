import React from 'react';
import './projects.css';
import BrineLogo from '../../assets/img/brine.png';
import KryptoLogo from '../../assets/img/krypto.png';

function Projects() {
  const personalProjectData = [
    {
      aTag: 'https://github.com/gurukishore111/Shopen',
      title: 'MERN',
      image:
        'https://res.cloudinary.com/gk1/image/upload/v1607677926/DoneWithIt_6_p6hg4d.png',
      desc: 'It is the Full-stack E-commerce Website 🛒 developed using MERN stack , Redux',
      subtitle: 'Shopen',
      video: 'https://www.youtube.com/watch?v=Vf-UjSj2Tho',
    },
    {
      aTag: 'https://github.com/gurukishore111/Multi-User-Blogging-Platform/',
      title: 'MERN + NEXT Js',
      image:
        'https://res.cloudinary.com/gk1/image/upload/v1607830791/Multi-User-Blogging-Platform_ogsntd.png',
      desc: 'It is the Full-stack Multi-Users Blogging platform 🤓 you can share the knowledge ✉️ and help others, This project developed by Node JS • Express • MongoDB • Next Js • React Js',
      subtitle: 'Blog Leaf',
      video: 'https://www.youtube.com/watch?v=KLDUDwb8PUk',
    },
    {
      aTag: 'https://github.com/gurukishore111/MERN-stack-Instagram-clone',
      title: 'MERN',
      image:
        'https://res.cloudinary.com/gk1/image/upload/v1607677400/DoneWithIt_4_jb6uyi.png',
      desc: 'It is the MERN stack social media network like Instagram 🖼️ .User can create a post and like, comment and follow.',
      subtitle: 'Instagram Clone',
      video: 'https://www.youtube.com/watch?v=wXYNeVNDOR0',
    },

    {
      aTag: 'https://github.com/gurukishore111/Full-Stack-Movie-Streaming-App',
      title: 'REACT NATIVE',
      image:
        'https://res.cloudinary.com/gk1/image/upload/v1612499297/Movie_Streaming_app_uada3n.png',
      desc: 'Its Movies Streaming 🎥 App build in React Native and Firebase.',
      subtitle: 'Movies Streaming 🎥 App',
      video: 'https://www.youtube.com/watch?v=UJ0yJSFS08E',
    },
    {
      aTag: 'https://github.com/gurukishore111/DoneWithit',
      title: 'REACT NATIVE',
      image:
        'https://res.cloudinary.com/gk1/image/upload/v1607675241/DoneWithIt_sklslr.png',
      desc: 'It is Hybrid Second hand items selling App 👉 build in React Native • Node Js.',
      subtitle: 'DoneWithit',
      video: 'https://www.youtube.com/watch?v=1gP5xpp7RPM',
    },
    // {
    //   aTag: 'https://github.com/gurukishore111/Monit-desktop-application-using-electron',
    //   title: 'ELECTRON JS',
    //   image:
    //     'https://res.cloudinary.com/gk1/image/upload/v1607676916/DoneWithIt_3_qtdinq.png',
    //   desc: 'It is a CPU management 🖥️ desktop application build using electron.',
    //   subtitle: 'Monit',
    //   video: 'https://www.youtube.com/watch?v=_FfJbkc7rkc',
    // },
    {
      aTag: 'https://github.com/gurukishore111/FreSize-Desktop-app-in-Electron',
      title: 'ELECTRON JS',
      image:
        'https://res.cloudinary.com/gk1/image/upload/v1607677650/DoneWithIt_5_mhj3wq.png',
      desc: 'It is an image 🖼️ compressing desktop application build using electron',
      subtitle: 'Fresize',
      video: 'https://www.youtube.com/watch?v=imWN4M5WiW8',
    },
  ];

  return (
    <>
      <section className="achievement section project" id="portfolio">
        <h2 className="section__title">Personal Project</h2>
        <span className="section__subtitle" style={{ visibility: 'hidden' }}>
          SOME STARTUPS AND COMPANIES THAT I BUILT TO CREATE THEIR TECH
        </span>

        <div className="achievement__container container grid project__container personal__project__container">
          {personalProjectData.map((item) => (
            <div
              className="achievement__content"
              key={item.subtitle}
              onClick={() => window.open(item.aTag, '_blank')}
            >
              <div>
                {/* <i className='uil uil-react achievement__icon'></i> */}
                <img
                  src={item.image}
                  alt={item.subtitle}
                  className="achievement__icon"
                />
                <h3 className="achievement__title">{item.subtitle}</h3>
                {/* <h3 className='achievement__tag'>{item.title}</h3> */}
                <h3 className="achievement__description">{item.desc}</h3>
              </div>
              {/* <span className='achievement__button'>
            View More
            <i className='uil uil-arrow-right achievement__button-icon'></i>
          </span> */}
            </div>
          ))}
        </div>

        <div className="project__button_container">
          <a
            href={'https://github.com/gurukishore111'}
            className="button button--flex "
            target="_blank"
          >
            View More Personal Project
            <i className="uil uil-arrow-right achievement__button-icon"></i>
            {/* <FileIcon /> */}
          </a>
        </div>
      </section>
    </>
  );
}

export default Projects;
